.itp-chat-window__user-sent-message-other {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  position: relative;
  margin-top: -0.5rem;
}

.itp-chat-window__user-sent-message-you {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  position: relative;
  margin-top: -0.5rem;
}

.itp-chat-window__user-sent-message-content {
  color: #6d28d9;
  font-family: "Source Sans Pro";
  font-size: 12px;
}
