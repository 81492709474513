.itp-sidebar {
  position: fixed;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 0px;
  width: 220px;
  padding-bottom: 0;
  padding-top: 0;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0px 4px 8px #949494;
  transform: translateX(0);
  transition: transform 0.2s ease;
}

.itp-sidebar-container-before {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  position: relative;
  height: 100%;
  touch-action: auto;
  overflow: hidden auto !important;
  overflow-anchor: none;
}

.itp-sidebar-containter {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
}

/* .itp-sidebar-containter-open {
  background-color: #fff;
  height: 100%;
  padding-top: 10px;
  transition: all 0.2s ease;
} */

.itp-sidebar-span {
  text-align: left;
  font-family: Montserrat;
  letter-spacing: 0px;
  color: #000;
}

.itp-sidebar-title-section {
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-bottom: 10px;
  margin-left: 20px;
}

.itp-sidebar-row-item {
  cursor: pointer;
  margin-left: 0px !important;
  padding: 10px !important;
  font-size: 14px;
  font-family: Montserrat;
  color: #3a3a3a99;
}

.itp-sidebar-title-section.no-start {
  margin-top: 2rem;
}

.itp-sidebar-row-item.start {
  padding-top: 10px;
}

.itp-sidebar-row-item.active {
  background: rgba(115, 103, 240, 0.12);
  border-radius: 0px;
  width: 100%;
  margin-left: 0px !important;
  padding-left: 0px !important;

  display: flex;

  border-left-color: #6D28D9;
  border-left-width: 3px;
  border-left-style: solid;
}

.itp-sidebar-row-item.active .itp-sidebar-col-span .itp-sidebar-span {
  box-shadow: none;
  text-align: left;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6D28D9;
  transition: all 0.5s;
  padding-left: 2px;
  font-weight: 600;
}

.itp-sidebar-span:hover {
  color: #6D28D9;
}

.itp-sidebar-icon {
  visibility: visible;
}

.itp-sidebar-icon:hover + .itp-sidebar-col-span > .itp-sidebar-span {
  color: #6D28D9;
}

.itp-sidebar-inboxes {
  background-color: #ffffff;
  height: auto;
  width: 100%;
  flex-grow: 1 !important;
}

.itp-sidebar-inboxes-title {
  text-align: left;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  margin: 10px 0 8px 20px;
}

.itp-sidebar-inboxes-subtitle {
  text-align: left;
  font-family: Montserrat;
  letter-spacing: 0px;
  color: #000;
  margin-bottom: 0;
  word-wrap: anywhere;
  font-size: 14px;
}

.itp-sidebar-inboxes-number {
  text-align: left;
  font-family: Montserrat;
  letter-spacing: 0px;
  color: #6D28D9;
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 12px;
}

.itp-sidebar-inboxes-circle-facebook {
  background: transparent url("../../assets/icons/facebook.svg") 0% 0% no-repeat
    padding-box;
}

.itp-sidebar-inboxes-circle-shared {
  background: transparent url("../../assets/icons/shared.svg") 0% 0% no-repeat
    padding-box;
}

.itp-sidebar-inboxes-circle-primary {
  background: transparent url("../../assets/icons/primary.svg") 0% 0% no-repeat
    padding-box;
}

.itp-sidebar-inboxes-circle-primary.active {
  margin-left: -12px;
}

.itp-sidebar-inboxes-circle-instagram {
  background: #974383 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #707070;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
}

.itp-sidebar-container-circle {
  margin-left: 16px;
  margin-right: -20px;
}

.itp-sidebar-inboxes-message {
  text-align: left;
  font: normal normal normal 8px/10px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background-color: black;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
  margin-top: -10px;
  margin-left: 18px;
  z-index: 2;
  position: relative;
}

.itp-sidebar-inboxes-message.primary {
  margin-top: -16px;
  margin-left: 16px;
}

.itp-sidebar-inboxes-container-subtitle {
  margin-top: -4px;
}

.itp-sidebar-container-notification {
  /* margin-bottom: 10px; */
  /* transition: all 0.5s; */
  padding: 10px 10px 10px 0px;
}

.itp-sidebar-container-notification.active {
  background-color: rgba(115, 103, 240, 0.12);
  cursor: pointer;
  border-radius: 10px;
}

.itp-sidebar-inboxes-message.primary.active {
  margin-left: -12px;
}

.itp-sidebar-inboxes-circle-instagram.active {
  margin-left: -16px;
}

.container-inboxes-data-instagram {
  visibility: visible;
}

.container-inboxes-data-instagram.active {
  margin-left: -8px;
  margin-right: 4px;
}

.container-inboxes-data-shared.active {
  margin-left: -12px;
  margin-right: 6px;
}

.itp-sidebar-inboxes-circle-shared.active {
  margin-left: -16px;
}

.itp-sidebar-inboxes-circle.active {
  margin-left: -16px;
}

.itp-sidebar-container-before::-webkit-scrollbar {
  width: 3px; /* Ancho del scroll */
}

.itp-sidebar-container-before::-webkit-scrollbar-track {
  background: #8787874d; /* Color de fondo del track (la barra detrás del scroll) */
}

.itp-sidebar-container-before::-webkit-scrollbar-thumb {
  background: rgba(
    112,
    112,
    112,
    0.5
  ); /* Color del thumb (la barra de scroll en sí) */
}

.itp-sidebar-inboxes-points {
  cursor: pointer;
}

.itp-sidebar-container-notification:hover {
  background-color: rgba(115, 103, 240, 0.12);
  cursor: pointer;
  border-radius: 10px;
  /* transition: all 0.5s; */
  /* padding: 10px 10px 10px 0px; */
}

.itp-sidebar-container-notification.active:hover {
  background-color: rgba(115, 103, 240, 0.12);
}

.itp-sidebar.hidden {
  transform: translateX(-100%);
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(115, 103, 240, 0.12);
  color: #6D28D9;
  font-weight: bold;
  margin-right: 10px;
}

.skeleton-loading {
  background-color: #f2f2f2;
  animation: skeleton-loading 1s infinite alternate;
}

@keyframes skeleton-loading {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0.5;
  }
}

.skeleton-shadow {
  background-color: #fff;
  animation: skeleton-shadow 1s infinite alternate;
}

@keyframes skeleton-shadow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

a.itp-sidebar-items {
  text-decoration: none;
  
  
}

.version-property {
  position: absolute;
  bottom: -10px;
  margin-left: 5px;
  opacity: 0.6;
}