.itp-container-search-input {
  display: flex;
  align-items: center;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  opacity: 1;
  width: 30rem;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 35px;
  background-color: #fff;
}

@media (min-width: 389px) and (max-width: 542px) {
  .itp-container-search-input {
    width: 20rem;
  }
}
@media (max-width: 388px) {
  .itp-container-search-input {
    width: 17rem;
  }
}

.itp-container-search-input.focused-input {
  border: 1px solid #6d28d9;
}

.itp-search-input {
  border: 0;
  outline: none;
  background-color: transparent;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  width: 100%;
}

input.itp-search-input::placeholder {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}
